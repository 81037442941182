<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-9 text-center mb-4">
            <img class="cover-img" src="assets/img/Mafia_Cover_6.png" alt="Mid America Fists In Action" >
        </div>
        <div class="col-md-9 text-center mb-4">						
            <p>
                Mid America Fists in Action (MAFIA) is an organization for those interested in fisting or handball. 
            </p>
            <p>
                This site contains information of a sexual nature and is intended for any adult who identifies as a man.
            </p>
            <p>
                If you do not wish to see content of this nature, we respectfully suggest that you leave this site.
            </p>
            <p>
                While we encourage visitors to our site, we do require that you be 21 or over to enter.
                If you wish to continue, click the buttons below.
            </p>
            <p>
                Please note that all our events take place in Chicago.
            </p>                     
            <section class="text-center mt-4">
                <a routerLink="../events" class="btn mafia-btn-otl mr-3 mb-4">Events</a>
                <a routerLink="../register" class="btn mafia-btn-otl ml-3 mr-3 mb-4">Register</a>
                <a routerLink="../join" class="btn mafia-btn-otl ml-3 mr-3 mb-4">Join</a>
                <a routerLink="../info" class="btn mafia-btn-otl ml-3 mb-4">Info</a>
            </section>
        </div>
        <!--<div class="col-md-9 text-center">
            <a href="https://www.squarepegtoys.com/" target="_blank">
                <img class="sponsor-img" src="assets/img/SQP_2020.jpg" alt="Square Peg Toys">
            </a>
        </div>-->
    </div>
</div>
<div class="container">
    <div class="row justify-content-center align-self-center">
        <div class="col-sm-10 tab-text">
            <h3 class="section-head">House Rules </h3>
            <p class="text-bold">
                MAFIA reserves the right to eject any party attendee at any time. Illegal drug use is stricly prohibited, as is being visibly intoxicated. Registration fees will not be refunded if you are ejected.
            </p>
            <p class="text">
                BEFORE THE PARTY - Do your primary clean out before you arrive. Touch-up facilities are limited. Bring your own standard shower shot nozzle. Please clean up the facility after you finish.
            </p>																																				
            <p class="text">	
                All attendees must be quiet as they arrive and leave the party site. You must be pre-registered to attend or you will be denied entry for the safety of everyone. Registrations will NOT be accepted at the door. 
            </p>
            <p class="text">	
                Play is restricted to the designated party area. No drugs allowed. Smoking/vaping is not permitted indoors, only in the designated smoking area outside. Anyone who becomes disruptive will be asked to leave. If you are a sponsored guest, your sponsor will be required to escort you home and may return later.
            </p>
            <p class="text">	
                TOPS - Disposable gloves are provided for your use. If you are wearing gloves, use a fresh pair of gloves for each session whenever you are topping. Condom use is strongly encouraged. After each session, wash your hands and arms using the anti-bacterial soap provided.
            </p>
            <p class="text">	
                BOTTOMS - Please bring your own personal lube. We have stopped providing Crisco as other lubes have become the standard. We strongly advise against sharing lubricant to reduce the risk of spreading bacteria. 
            </p>
            <p class="text">
                Please use the provided pads on the sling and floor, then discard them when finished. When finished, please clean your play area thoroughly (including the floor) and discard all used materials in the garbage cans provided. Use the supplied spray disinfectant and wipe down the equipment. Please remove all personal belongings from the play area when you leave.
            </p>
            <p class="text">	
                Do not jump or bounce in the slings. Do not occupy the play equipment when you are not playing. Please clean the equipment quickly after you are finished playing so that others may enjoy.
            </p>
            <p class="text">	
                Keep conversations to a minimum in the play area and use the social area to chat with others when not playing. When playing, be considerate of others by limiting loud outbursts, as this may be disruptive to their scene.
            </p>
            <p class="text">
                Do not participate in another's session unless you are invited. Keep “voyeurism” at a comfortable distance from those playing.
            </p>
            <p class="text">	
                Personal toys are allowed should you desire to bring them. No photography of any kind is allowed anywhere on the party premises during an event.
            </p>
            <p class="text">
                Clothes check is provided at Dungeon Parties. You are ultimately responsible for your small valuables, such as wallet and cell phone. Bring only bring what you need in the way of valuables. Mid America Fists In Action, Inc. cannot be held responsible for loss of your property.
            </p>
            <p class="text">	
                For more information please contact us at <strong><a href="mailto:partychairman@mafiaff.org">partychairman&#64;mafiaff.org</a></strong>
            </p>
        </div>
    </div>
</div>
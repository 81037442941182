<div class="audience mt-5">
    <div class="container">
        <div class="row ">
            <div class="col-12">
                <nav ngbNav #nav="ngbNav" class="nav-tabs justify-content-center">
                    <ng-container ngbNavItem>
                        <a ngbNavLink>
                            <div class="tab-text text-uppercase text-center">House Rules</div>
                        </a>
                        <ng-template ngbNavContent>
                            <house-rules></house-rules>
                        </ng-template>
                    </ng-container>
                    <ng-container ngbNavItem>
                        <a ngbNavLink>
                            <div class="tab-text text-uppercase text-center">Play Space</div>
                        </a>
                        <ng-template ngbNavContent>
                            <play-space></play-space>
                        </ng-template>
                    </ng-container>
                    <ng-container ngbNavItem>
                        <a ngbNavLink>
                            <div class="tab-text text-uppercase text-center">Members Area</div>
                        </a>
                        <ng-template ngbNavContent>
                            <members-area></members-area>
                        </ng-template>
                    </ng-container>
                </nav>
            </div>
        </div>
    </div>
</div>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
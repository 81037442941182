
<ul class="event-list">
    <div *ngFor="let event of events">
    <li *ngIf="event.isCurrent">
        <time>
            <span class="day">{{ event.day }}</span>
            <span class="month">{{ event.monthName }}</span>
            <span class="year">{{ event.year }}</span>
            <span class="time">{{ event.time }}</span>
        </time>
        <img style="display:block;" *ngIf="event.imageSrc" alt={{event.imageAltText}} src={{event.imageSrc}} />
        <div class="info">
            <h2 class="title">{{ event.title }}</h2>
            <p class="desc">{{ event.location }}</p>
            <p class="desc">{{ event.time }}</p>
            <p *ngIf="event.showRegisterButton" class="desc"><a routerLink="../register" class="btn mafia-btn-sml mb-3">Click to Register</a></p>
        </div>
    </li>
    <li *ngIf="event.isCurrent" class="event-details" style="margin-top: -17px">
        <div class="info" style="height:auto">
            <p class="desc" [innerHTML]=event.description></p>    
        </div>
    </li>
    </div>
</ul>


<div class="container">
    <div class="row justify-content-center align-self-center">
        <div class="col-sm-10 tab-text">
            <h3 class="section-head">Play Space</h3>
            <p class="text">
                Each sling has a light, stool, small table, and trash can.
            </p>
            <p class="text">
                In addition to slings, there are mats and other areas to play on and in, let your imagination run wild!
            </p>
            <p class="text">
                We provide paper towels, pads, and gloves. You should bring your own personal lube supply.
            </p>
            <p class="text">
                Our multi-speaker sound system delivers great mood setting music.
            </p>
            <p class="text">	
                Our private shower facility is only for touch ups. Bring your own standard nozzle.
            </p>
            <p class="text">	
                We have a changing/social area and provide bags for your clothing.
            </p>
            <p class="text">
                Beverages and snacks are included for when you need a break.
            </p>
            <p class="text">
                Anyone playing 30 minutes before we close must help with equipment take down.
            </p>
            <p class="text">
                Join us and enjoy some hot FF play!
            </p>
        </div>
    </div>
</div>
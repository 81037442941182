<div class="container">
    <div class="row no-gutters align-items-center h-100">
        <div class="col-xs-12 col-sm-offset-2 col-sm-8">

            <h3 class="section-head">Events</h3>
            <p class="text">
                Contact <strong><a href="mailto:partychairman@mafiaff.org">partychairman&#64;mafiaff.org</a></strong> if you have any questions
            </p>
            
            <event-list></event-list>
        </div>
    </div>
</div>
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopMenuComponent } from './top-menu.component';
import { MainFooterComponent } from './main-footer.component';

import { HomePageComponent } from './home-page/home-page.component';

import { EventPageComponent } from './event-page/event-page.component';
import { EventListComponent } from './event-page/event-list.component';

import { InfoPageComponent } from './info-page/info-page.component';
import { HouseRulesComponent } from './info-page/house-rules.component';
import { PlaySpaceComponent } from './info-page/play-space.component';
import { MembersAreaComponent } from './info-page/members-area.component';
import { JoinPageComponent } from './join-page/join-page.component';
import { RegisterPageComponent } from './register-page/register-page.component';

@NgModule({
  declarations: [
    AppComponent,
    TopMenuComponent,
    MainFooterComponent,
    HomePageComponent,
    EventPageComponent,
    EventListComponent,
    InfoPageComponent,
    HouseRulesComponent,
    PlaySpaceComponent,
    MembersAreaComponent,
    JoinPageComponent,
    RegisterPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

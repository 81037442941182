<nav class="navbar navbar-dark fixed-top navbar-expand-md navbar-custom">
	<div class="container">
		<a class="navbar-brand" routerLink="home">
			<img id="mafiaLogo" src="assets/img/Mafia_small.png" class="d-inline-block align-top" alt="Mafia FF Logo">
		</a>
		<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsDefault" aria-controls="navbarsDefault" aria-expanded="false" aria-label="Toggle navigation">
		<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarsDefault">
			<ul class="navbar-custom navbar-nav ml-auto">
				<li class="nav-item active">
					<a class="nav-link" routerLink="home">Home</a>
				</li>				
				<li class="nav-item">
					<a class="nav-link" routerLink="events">Events</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" routerLink="register">Register</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" routerLink="join">Join The Club</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" routerLink="info">Info</a>
				</li>
			</ul>              
		</div>
	</div>
</nav>
import { Component, OnInit } from '@angular/core';

import { EventsService } from '../events.service';

@Component({
  selector: 'event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.sass']
})
export class EventListComponent implements OnInit {
  events = [];

  constructor(
    private eventService: EventsService
  ) { 
    this.events = eventService.getEvents();
  }

  ngOnInit(): void {
  }

}

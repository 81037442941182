import { Component, OnInit } from '@angular/core';

function navBarScroll() {
	// jQuery to collapse the navbar on scroll
    $(window).scroll(function() {
        if ($(".navbar").offset().top > 50) {
            $(".fixed-top").addClass("top-nav-collapse");
			$("#mafiaLogo").attr("src","assets/img/Mafia_small.png");
        } else {
            $(".fixed-top").removeClass("top-nav-collapse");
			$("#mafiaLogo").attr("src","assets/img/Mafia_small.png");
        }
    });
}

@Component({
  selector: 'top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.sass']
})
export class TopMenuComponent implements OnInit {
  name = 'Angular';
  
  ngOnInit(){
    navBarScroll()
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { EventPageComponent } from './event-page/event-page.component';
import { InfoPageComponent } from './info-page/info-page.component';
import { RegisterPageComponent } from './register-page/register-page.component';
import { JoinPageComponent } from './join-page/join-page.component';


const routes: Routes = [
  { path: 'events', component: EventPageComponent },
  { path: 'info', component: InfoPageComponent },
  { path: 'home', component: HomePageComponent },
  { path: 'join', component: JoinPageComponent },
  { path: 'register', component: RegisterPageComponent },
  { path: 'event',
    redirectTo: '/events',
    pathMatch: 'full'
  },
  { path: '', 
    redirectTo: '/home', 
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

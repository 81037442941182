import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'house-rules',
  templateUrl: './house-rules.component.html',
  styleUrls: []
})
export class HouseRulesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
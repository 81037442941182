import { Injectable } from '@angular/core';
import Events from './events.json'

@Injectable({
  providedIn: 'root'
})
export class EventsService {
events = [];
eventDate: Date;
currentDate: Date = new Date();

getEvents() {
  this.events = Events;
  this.events.forEach(element => {
    this.eventDate = new Date(element.date);
    this.eventDate.setHours(23, 59, 59);
    element.isCurrent = this.isCurrentEvent();
    element.monthName = this.eventDate.toLocaleString('default', { month: 'short' });
    element.year = this.eventDate.getFullYear();
    element.month = this.eventDate.getMonth();
    element.day = this.eventDate.getDate();
  });
  return this.events;
}

isCurrentEvent() {  
  return this.eventDate.getTime() >= this.currentDate.getTime();
}

  constructor() {
    console.log('Reading in json file');
    console.log(Events);
   }
}

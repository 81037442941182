<div class="container">
    <div class="row no-gutters align-items-center h-100">
        <div class="col-xs-12 col-sm-offset-2 col-sm-8">
            <h3 class="section-head">Join The Club</h3>
            <br>
            <p class="text">	
                <strong><a href="https://docs.google.com/forms/d/e/1FAIpQLSdYjSf6wN2hA9I0O75L4tjCtNn8FJF-1Wa158ZFllOKf6PVSg/viewform" target="_blank" class="btn mafia-btn-otl mb-3">NEW Member Application</a></strong>													
            </p>
            <p class="text">
                <strong><a href="https://docs.google.com/forms/d/e/1FAIpQLSe4lhPoakOAslP0RICkr6ZcQWKdkDG8lgy43OFFPnaJkC9h9g/viewform" target="_blank" class="btn mafia-btn-otl mb-3">RENEWING Member Application</a></strong>													
            </p>
            <p class="text">
                Membership in MAFIA is open to those who identify as male, are 21 years of age or older, and who have demonstrated an interest and proficiency at Fisting or Handballing. Although membership in MAFIA is limited to self-identified males, we do not discriminate on the basis of race, religion, ethnicity, physical ability, HIV status, partnership/marital status, or other protected categories.
            </p>
            <p class="text">
                MAFIA members are required to comply with the organization's By-Laws and House Rules. Sanctions for violations of the rules and policies of MAFIA are defined in the By-Laws. The MAFIA membership list is confidential and is not disclosed to non-members.
            </p>
            <p class="text">
                To apply for full membership, you must be sponsored by two current members in good standing.
            </p>
            <p class="text">	
                The Membership Chair will report the names of applicants at the next regular MAFIA Board of Directors meeting. Those names will be published in the newsletter for comment by any current member. At the following Board meeting, any comments received concerning the applicant will be presented and discussed. Then a vote of the Board will be taken to accept or reject the applicant. Applicants will be notified by mail of the acceptance or denial of their application.
            </p>
        </div>
    </div>
</div>
